.class-detail-slot .detail-head .class-img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    overflow: hidden;
  }
  
  .font-arialround {
    font-family: "Arial-Rounded";
  }
  
  .class-detail-slot-box .profile-img {
    width: 60px;
    height: 60px;
    border-radius: 100%;
    overflow: hidden;
  }
  
  .filtermodal .modalclose {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #000;
    font-weight: 500;
    font-size: 1.1rem;
  }
  
  .modalclose {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  /* .addfilter-modal .profile-gender label{
      background-color: #fff;
      border: 2px solid #fff;
  } */
  .addfilter-modal .profile-gender-button input[type=radio] + label {
    background-color: #fff;
    border: 2px solid #fff;
  }
  
  .class-detail-slot {
    min-height: 280px;
  }
  
  .class-detail-slot-box {
    /* min-height: 135px; */
  }