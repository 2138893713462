.tabbox h4 {
    font-family: "Arial-Rounded";
  }
  
  .client-refer .custom-controls-stacked label {
    padding: 0;
    min-height: 1px;
  }
  
  .staff-studio-details {
    min-height: 1px !important;
    height: auto;
  }
  
  .availibility-slot .title {
    font-family: "Arial-Rounded";
  }
  
  .availibility-slot .title {
    font-family: "Arial-Rounded";
  }
  
  .calender-head {
    font-family: "Arial-Rounded";
  }
  
  .font-arialround {
    font-family: "Arial-Rounded";
  }
  
  .availibility-slot .mat-form-field {
    display: block;
    width: 100%;
    color: #000;
  }
  
  .available-slots .client-refer {
    min-height: 1px !important;
    height: auto;
  }
  
  .available-slots .btn-green-grad {
    width: 35%;
  }
  
  /*  */
  /* .client-refer .custom-control.ios-switch .ios-switch-control-indicator{
      background-image: linear-gradient(90deg, #32C3F1 0%, #2AACE3 50%, #1B96D3 99%);
  } */
  /* border: 3px solid transparent; 
              border-image: linear-gradient(to right, green, lightgreen); */
  /* .client-refer .custom-control.ios-switch .ios-switch-control-input:checked~.ios-switch-control-indicator{
      border: 12px solid transparent; 
      border-image: linear-gradient(90deg, #32C3F1 0%, #2AACE3 50%, #1B96D3 99%); 
  }  */