.select-class {
    background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right !important;
    background-position-x: right;
    -webkit-appearance: none !important;
    background-position-x: 95% !important;
    width: 100%;
    height: 50px;
    border-radius: 0px;
    border-bottom: 1px solid #0e8542;
    border-top: none;
    border-right: none;
    border-left: none;
  }
  