

.demo-bottom-section input.form-control.form-control-own {
    background: transparent;
    border-radius: 0px;
    height: 3rem;
    color: #222222;
    text-align: left;
    font-size: 0.875rem;
    letter-spacing: 0px;
    line-height: 1.25rem;
    border-bottom: 1px solid #0e8542!important;
    border-top: none!important; 
    border-right: none!important;
    border-left: none!important;
}

.demo-bottom-section small.text-danger {
    float: left;
}

.swal2-popup.swal2-modal.swal2-icon-error.swal2-show {
    min-height: 300px;
    background-image: linear-gradient( 270deg , #0D92D1 0%, #36C0EE 100%);
    border-radius: 10px;
    border: 0;
    color: #fff;
}