.CardTable .table {
    width: 100%;
    font-size: 15px;
  }
  
  /* Make table header cells adjust width based on content */
 .CardTable .table th {
    white-space: nowrap;
  }
  
  /* Make table body cells adjust width based on content */
 .CardTable .table td {
    white-space: nowrap;
  }
 .CardTable .table tbody td {
    padding: 15px;
    font-weight : 400px;
  }



  .CardTable .table tbody td button.btn.btn.btn-primary {
    background: linear-gradient(90deg, #32c3f1, #2aace3 50%, #1b96d3 99%);
    border: 0px;
    padding: 5px;
    font-size: unset;
    font-weight:400;
    text-transform: uppercase;
    color: #fff;
}