.multis {
    display: inline-block;
    border-bottom: 1px solid #0e8542;
    border-top: none;
    border-right: none;
    border-left: none;
    width: 100%;
    padding: 6px 12px;
    margin-bottom: 0;
    font-weight: 400;
    line-height: 1.52857143;
    text-align: left;
    vertical-align: middle;
    cursor: pointer;
    background-image: none;
  }