.AboutSection p span{
    background-color: transparent!important;
    color: #77787b !important;
    text-align: justify !important;
}
.AboutSection a{
    font-family: "HelveticaNeue";
    font-weight: normal;
    color: #0e8542;
    line-height: normal;
    text-decoration: none;
}