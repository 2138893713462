input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button !important;
  position: absolute;
  right: 5%;
  height: 50%;
}

/***************************** Home Page Css Start */

.userClassesContent {
  padding: 2rem 2rem;
  background: #f2f3f5;
  min-height: 450px;
  height: 100%;
  overflow: scroll;
}

p.text-right {
  display: flex;
  justify-content: space-between;
  align-items: right;
  margin: 0px;
}

span.dateTime h5 {
  color: #02793f;
  font-weight: 700;
  margin: 0px;
}

.PlusDropdown.show.dropdown .dropdown-menu.show {
  box-shadow: 0px 4px 20px 4px rgba(0, 128, 0, 0.2);
  border: 0;
  border-radius: 10px;
  width: 300px;
}

a.dropDownOption.dropDownLink.dropdown-item a {
  color: #0e8543 !important;
}

btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.RightSide nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  background: #f2f2f2 !important;
  padding: 0px;
}

.ClassesDetails {
  background: #f2f3f5;
}

/* Home Page Css End *****************************/

/*******************ClassSchedule Pages Css Start */
.FontIconGreen {
  color: green !important;
}

.blueback-pass.card .cardImg p {
  font-size: 12px !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px !important;
  line-height: 1;
  opacity: 1 !important;
  color: #ffffff !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next {
  right: -15px !important;
  top: 200px !important;
  left: 51%;
}

.slick-prev {
  right: 2px !important;
  top: 200px !important;
  left: 45% !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.1em !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;

}

/* ClassSchedule Pages Css End ********************/

/****************************** SideBar Css Start **/

.option-list span svg {
  font-size: 1.2rem;
}

div#mainpageWrapper a {
  text-decoration: none;
}

.business-box.p-3:hover {
  background: #0e8543;
}

.fade.CommonModal.modal.show .modal-content {
  min-height: 300px;
  background-image: linear-gradient(270deg, #0d92d1 0%, #36c0ee 100%);
  border-radius: 10px;
  border: 0;
  max-width: 330px;
  max-height: 400px;
  height: 400px;
  margin: 0px auto;
}

button.w-100.btn-Modal.btn.btn-primary {
  background: #fff;
  color: #2aace3;
}

.fade.CommonModal.modal.show .modal-dialog {
  margin: 10rem auto;
}

/****************************** SideBar Css End **/

/*********************** Full Business Css Start  */

.business-box.p-3 {
  background: #36c0ee;
  border-radius: 10px;
  color: #fff;
  min-height: 187px;
  box-shadow: 0 2px 6px 0 rgba(14, 133, 66, 0.2);
}

/*********************** Full Business Css End  */

.custom-dialog-container {
  background-image: linear-gradient(270deg, #0d92d1 0%, #36c0ee 100%);
  /* Replace with your desired background color */
}

.textWhite {
  color: white !important;
}

div:where(.swal2-container) div:where(.swal2-popup) {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 20em !important;
  max-width: 100%;
  padding: 0 0 1.25em;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}

.modalbtn {
  color: #1d96d3 !important;
  padding: 8px 110px;
  background: #ffff !important;
}

.custom-button-size {
  border: 1px solid #ffffff !important;
  box-shadow: 0 2px 6px 0 rgba(14, 133, 66, 0.2) !important;
  /* Define the desired button size styles */
}

.active-manu {
  background: #0e8543 !important;
}

.hideSide {
  position: absolute;
  z-index: 9;
}

.btnupdate {
  width: 75px !important;
  height: 40px !important;
  padding: 0px !important;
}

.padHead {
  margin-left: 80px;
}

a.dropDownOption.dropDownLink.UserProfileItem.dropdown-item a {
  color: #000 !important;
}

.blueback-pass.card .card-body {
  text-align: center;
}

.blueback-pass.card {
  max-height: 175px;
  min-height: 175px;
  max-width: 370px;
  margin: 1rem 0rem;
}

.slick-slider.slick-initialized svg {
  color: #fff;
}

.fade.classScheduleModal.modal.show .modal-body {
  max-height: 350px;
  height: 300px;
  display: grid;
  justify-content: center;
  align-items: center;
}

footer.footer.py-3.bg-white.clearfix.signin-footer {
  min-height: 120px;
}

a.SocialIcon-Facebook {
  background: #3b5998 !important;
  text-decoration: none;
}

a.SocialIcon-Insta {
  background: linear-gradient(45deg,
      #f09433 0%,
      #e6683c 25%,
      #dc2743 50%,
      #cc2366 75%,
      #bc1888 100%) !important;
  text-decoration: none;
}

a.SocialIcon-Twitter {
  background: #00acee !important;
  text-decoration: none;
}

.myProfileDropDown.show.dropdown button {
  background-color: transparent !important;
}

input.SearchClientPanel.form-control {
  border: 1px solid #828282 !important;
  border-radius: 0.5rem;
  box-shadow: 0px 0px 0px #000;
}

.table-long-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 200px;
}

div#react-select-3-listbox {
  padding: 10px 0px;
  width: 100%;
  z-index: 9999;
}

div#react-select-2-listbox div.multiselect{
  font-size: 14px;
}
div#react-select-3-listbox div.multiselect{
    font-size: 14px;
}
div#react-select-4-listbox div.multiselect{
    font-size: 14px;
}
div#react-select-5-listbox div.multiselect{
    font-size: 14px;
}

.custom-option-menu {
  margin: 15px 0px;
}

.content-head span {
  display: none;
}

.content-head .form-control.css-b62m3t-container {
  padding: 0px;
}

span.MultiSelectOption {
  padding: 0.2rem 1rem;
}

.menu-container {
  background-color: #fff;
  box-shadow: 0px 5px 15px #0000002a;

}

.search-container {
  border: 1px solid hsl(0, 0%, 70%);
  padding: 5px;
  border-radius: 10px;
}

.top-container {
  height: 70vh !important;
}

.AddClientForm {
  margin: 0rem 0rem 1rem 0rem;
  border-radius: 10px;
}

a.listCardLink {
  text-decoration: none;
}

.love-serch.row ul {
  position: absolute;
  background: #fff;
  box-shadow: 0px 6px 10px #4444442e;
  max-width: 420px;
  max-height: 450px;
  overflow: scroll;
}

.customerProfileImg img {
  border-radius: 100px;
  box-shadow: 0px 5px 10px #00000024;
  width: 150px;
  height: 150px;
  object-fit: contain;
}

input.customerfile {
  width: 150px;
  height: 150px;
  position: absolute;
  top: 0px;
  opacity: 0;
  right: 30%;
}

.customerProfileImg {
  text-align: center;
}

.customerProfileImg svg {
  background: #ddd;
  padding: 5px;
  font-size: 34px;
  border-radius: 30px;
}

.fade.CustomerProfile.modal.show .modal-dialog {
  max-width: 800px !important;
  padding: 1rem;
}

.ant-picker.ant-picker-range.css-dev-only-do-not-override-1m62vyb {
  display: flex !important;
  padding: 12px 11px !important;
}

.ant-picker.ant-picker-range {
  display: flex !important;
  padding: 12px 11px !important;
}

button.btn.btn-outline.SearchButtonIcon.btn.btn-primary {
  border: 0px;
  box-shadow: 0px 0px 0px;
  font-size: 34px;
  margin: 25px 0px 0px 0px;
  padding: 0px;
}

.FamilyMember-pass.card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: #f2f3f5;
  border: 0px;
  box-shadow: 0px 5px 10px rgb(34 34 34 / 22%);
}

.FamilyMember-pass.card img.card-img-top {
  width: 100px;
  height: 100px;
  border: 1px solid;
  border-radius: 100px;
  margin: 0px 15px;
}

.AddClientContent.ViewShift input {
  border: 0px solid #000 !important;
}

.AddClientContent.ViewShift label {
  border: 0px solid #000 !important;
  font-size: 1rem;
}

.AddPassContent {
  margin: 1rem;
  background: #fff;
  padding: 1rem;
  border-radius: 15px;
}

.grropCard {
  background: lightgrey;
  padding: 5px 15px;
}

.fc-description {
  padding: 10px;
  color: #fff;
  text-wrap: wrap;
}

.appointmentPlus.WorkshopPlus {
  justify-content: end;
}

.pricing-card.active {
  box-shadow: 0 13px 25px 0 rgba(14, 133, 66, 0.3);
  transform: scale(1.1);
  opacity: 1;
  border: 1px solid #55b06f;
  background: linear-gradient(90deg, #32c3f1, #2aace3 50%, #1b96d3 99%);
  color: #fff;
  z-index: 999;
}

.studioPricingCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.PricingDetailCard.mt-2 a.text-uppercase.header-css {
  background: #2eb7ea;
  padding: 10px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
}

.existingClassesText ul li {
  list-style: outside;
  font-size: 1.2rem;
  line-height: 1.6;
  color: green;
  font-weight: 700;
}

.ProductCardList.card {
  box-shadow: 0 2px 6px 0 rgba(14, 133, 66, 0.2);
  border-radius: 10px;
  background: #fff;
  border: 0px;
  margin-bottom: 10px;
  min-height: 300px;
  text-decoration: none;
}

.ProductCardList.card .card-body {
  height: 155px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProductCardList.card img.card-img-top {
  object-fit: fill;
  width: 200px;
  height: 200px;
  /* border-radius: 100%; */
}

.ProductCardList.card .cardImg {
  margin: 10px auto;
}

a.productCardsAnchor {
  text-decoration: none;
}

.productimg {
  padding: 1rem 1rem;
  background: #fff;
  border-radius: 25px;
}

.ListProductForm {
  background: #fff;
  padding: 1rem 2rem;
  border-radius: 25px;
}

.plansdetails {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  background: #d9d9d9;
  padding: 0.5rem;
  border-radius: 10px;
  margin: 1rem 0rem;
}

button.changeLink.btn.btn-primary {
  background: transparent;
  color: #2db6e9;
  font-size: 1rem;
}

button.changeLink.btn.btn-primary:hover {
  background: transparent;
  color: #2db6e9;
  font-size: 1rem;
  box-shadow: 0px 0px 0px #000;
}

.NewCardForm {
  margin: 2rem 0rem;
}

img.img-fluid.instructorProfile {
  width: 50px;
}

.instructorDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.instructorDetail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 1rem;
  box-shadow: 0px 5px 10px #00000026;
  border-radius: 5px;
}

.PurchaseProfileProduct {
  display: flex;
  /* justify-content: start; */
  align-items: center;
  margin: 1rem 0rem;
  gap: 20px;
}

.PurchaseProfileProduct img {
  width: 100px;
  border-radius: 100px;
}

button.AddCartItem.btn.btn-primary {
  float: right;
}

.product-Item {
  max-height: 500px;
  overflow: scroll;
}

span.AddCartHover {
  width: 92%;
  height: 100%;
  position: absolute;
  display: inline-block;
  background: #00000036;
  z-index: 999;
  text-align: center;
  top: 50;
  display: none;
}

a.productCardsAnchor:hover>.AddCartHover {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.AddCartHover svg {
  font-size: 30px;
  color: #000;
  background: white;
  padding: 0.3rem;
  border-radius: 30px;
}

.blueback-pass.card:hover>span.AddCartHover {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

button#pageDropDown {
  display: none;
}

button.HoursModalPlus.btn.btn-primary {
  background: transparent;
  color: #4CAF50;
  font-size: 3rem;
  padding: 0px;
}

button.HoursModalPlus.btn.btn-primary:hover {
  background: transparent;
  color: #339036 !important;
  font-size: 3rem;
  box-shadow: 0px 0px 0px #000;
  padding: 0px;
}

button.HoursModalPlus.btn.btn-primar:focus,
button.HoursModalPlus.btn.btn-primar.focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0) !important;
}

.modal-dialog.HoursOperationModal {
  max-width: 800px;
}


button.HoursModalMinus.btn.btn-primary {
  background: transparent;
  font-size: 3rem;
  padding: 0px;
  color: #ff4040;
}


button.HoursModalMinus.btn.btn-primary:hover {
  background: transparent;
  font-size: 3rem;
  padding: 0px;
  box-shadow: 0px 0px 0px #000;
  color: #e23b3b !important;
}

button.cancelBtn.btn.btn-outline {
  box-shadow: 0px 0px 0px #000;
  border: 0px solid;
  color: #f00;
}

button.fc-button {
  text-transform: capitalize !important;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus,
.fc .fc-button-primary:not(:disabled):active:focus {
  box-shadow: rgb(76 91 106 / 0%) 0px 0px 0px 0.2rem;
  background: #72b086;
}

.ServiceCardBox.card .card-body p.card-text {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
}

button.productCardsAnchor {
  width: 100%;
}

button.fc-today-button.fc-button.fc-button-primary {
  background: #02793f !important;
  opacity: 1;
}

.AmountPayable {
  padding: 2rem;
}

.PaymentTabs button.nav-link {
  background: white;
  box-shadow: 0px 0px 10px #0000001c;
}

.PaymentTabs .nav-link.active {
  background: linear-gradient(90deg, #32c3f1, #2aace3 50%, #1b96d3 99%) !important;
  color: #fff;
  border: 0px !important;
}

h6.sameAbove {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h6.sameAbove span {
  color: #1d96d3;
}

.MainInputFieldINDIC.input-group {
  flex-wrap: unset;
  border: 1px solid #02793f;
  border-radius: 5px;
  max-width: 150px;
  width: 130px;
}

.MainInputFieldINDIC.input-group button {
  border: 0px;
  font-weight: 900;
  background: #fff;
  color: #02793f;
  font-size: 22px;
}

.MainInputFieldINDIC.input-group button:focus {
  box-shadow: 0px 0px 0px 0px;

}

div#CheckOutTable {
  padding-left: 120px;
}

.instructorimg img {
  width: 20px;
}

.instructorimg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
}

.instructorimg p {
  margin-bottom: 0px;
}

.ClassesDetails .row.react-bootstrap-table-pagination {
  display: none;
}

.ClientImg {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  gap: 5px;
}

.ClientImg img {
  width: 30px;
  height: 30px;
}

table.table.table-striped.table-hover.table-bordered a {
  text-decoration: none;
  color: green;
  font-weight: 600;
}

button.btn.btn-outline.FontIconGreen.btn.btn-Outline {
  padding: 0px;
  background: transparent;
  box-shadow: 0px 0px 0px 0px;
}






/* ****************************** Media Query Start */
@media (min-width: 1420px) {


  .LeftSide div#leftMenusidebar {
    display: block !important;
  }
}

@media (min-width: 768px) {
  .LeftSide div#leftMenusidebar {
    display: block !important;
  }

}

@media (min-width: 420px) {
  .LeftSide div#leftMenusidebar {
    display: none;
  }



  .PlusDropdown.dropdown button {
    position: relative;
    float: right;
  }
}

.custom-header th {
  width: 100%;
}

.fade.HoursOpreation.modal.show .modal-dialog .modal-content {
  height: 100%;
}

.carousel.slide .carousel-indicators {
  display: none;
}

button.openhours-btn.btn.btn-primary {
  background: transparent;
  font-size: 16px;
  width: 100%;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "HelveticaNeue";
}

button.openhours-btn.btn.btn-primary:hover {
  background: transparent;
  box-shadow: 0px 0px 0px 0px;
  color: #fff !important;
}

._3curk {
  position: relative !important;
  display: block !important;
  background: none !important;
}

.fc-timegrid-event .fc-event-time {
  font-size: 1rem !important;
  margin-bottom: 1px;
  white-space: nowrap;
  display: none;
}

table.fc-list-table .fc-event {
  border: 0px solid #ddd !important;
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.0) !important;
  background-color: #e4e7ec !important;
  color: #000 !important;
  font-size: 16px;
}

.fc .fc-list-event:hover td {
  background-color: green;
  color: #fff;
}

.fade.SubcriptionPlan.custom-modal-payment.modal.show .modal-content {
  padding: 20px;
  background: #f0f0f0;
  border-radius: 10px;
  background: #f2f3f5;
  height: auto;
  width: 100%;
}

.selected-date {
  background-color: #3fa8f4;
  color: white;
}

.solution-image-right.wow.animated.slideInRight {
  text-align: center;
}

.solution-image-right.wow.animated.slideInRight img {
  max-width: 450px !important;
  width: 100%;
}

/* section.addClient.innerContent span.AddCartHover {
  display: none;
} */
/* ****************************** Media Query End */