.special-date .mat-calendar-body-cell-content {
    background: linear-gradient(270deg, #0D8340 0%, #75BA81 100%);
    color: #fff;
  }
  
  .special1-date .mat-calendar-body-cell-content {
    background: linear-gradient(270deg, #F40808 0%, #AC0606 100%);
    color: #fff;
  }
  
  .mat-calendar-body-label {
    padding-top: unset !important;
  }
  
  .mat-calendar-body-selected {
    background-color: #2baee4;
    color: white;
  }